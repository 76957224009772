import "./App.css";
import AudioPlayer from "./AudioPlayer";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="audio-player-container">
          <AudioPlayer src={"/audio.mp3"} trackName={"The Song"} />
        </div>
        <img
          src={"/album-cover.png"}
          className="App-logo"
          alt="logo"
          style={{ borderRadius: 9999 }}
        />
        <h1>LISTEN, FAGGOTS</h1>
      </header>
    </div>
  );
}

export default App;

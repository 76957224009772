import React, { useState, useRef, useEffect } from "react";
import "./AudioPlayer.css";

const SONG_NAMES = [
  "fish",
  "healing moments",
  "mother dear",
  "p-p-p-power",
  "star kid",
  "tungsten",
  "wanna wanna",
];

const getSongLocation = (songName = "") =>
  `/songs/${songName.split(" ").join("-")}.mp3`;

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTrack, setCurrentTrack] = useState(
    Math.floor(Math.random() * SONG_NAMES.length)
  );

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = getSongLocation(SONG_NAMES[currentTrack]);
      audioRef.current.play();
      setIsPlaying(true);
    }
  }, [currentTrack]);

  const handleNext = () =>
    setCurrentTrack((currentTrack + 1) % SONG_NAMES.length);

  const handlePrev = () =>
    setCurrentTrack(currentTrack - (1 % SONG_NAMES.length));

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  return (
    <div className="audio-player cute-bubbly">
      <h3 className="track-name">{SONG_NAMES[currentTrack]}</h3>
      <div className="controls">
        <audio ref={audioRef} src={getSongLocation(SONG_NAMES[currentTrack])} />
        <div className="buttons-container">
          <button className="prev-btn" onClick={handlePrev}>
            ⏮
          </button>

          <button className="play-pause-btn" onClick={togglePlayPause}>
            {isPlaying ? "⏸" : "▶️"}
          </button>
          <button className="next-btn" onClick={handleNext}>
            ⏭
          </button>
        </div>
      </div>
      <input
        className="bubble-slider"
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
      />
    </div>
  );
};

export default AudioPlayer;
